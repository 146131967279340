<template>
	<v-container fluid>
		<img class="logo" src="../../assets/img/logo_white.png" >
	</v-container>
</template>

<script>
export default {
	name: "HomePage",
	mounted() {
		this.$store.dispatch('toggle', false);
	},
	destroyed() {
		this.$store.dispatch("toggle", true);
	}
};
</script>

<style scoped>

.container {
	height: 100%;
	padding: 0px;
	background: url("../../assets/img/abismo-background.png");
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	width: 100%;
	display: flex;
}

.logo {
	display: block;
	margin: auto;
	width: 40%;
}

@media screen and (max-width: 1264px) and (min-width: 960px) {
	.logo {
		width: 60%;
	}
}

@media screen and (max-width: 960px) {
	.logo {
		width: 80%;
	}
}

</style>